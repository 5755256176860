<template>
    <div class="bid">
        <!-- 未出价 -->
       <van-pull-refresh class="refresh" v-model="isLoading" @refresh="onRefresh">
           <van-list
            v-model="loading"
            :finished="finished"
            finished-text="我是有底线的"
            :immediate-check="false"
            @load="onLoad"
            :offset="20"
            >
                <div class="list_no" v-for="(item,index) in tableData" :key="index" @click="information(item)">
                    <p style="margin: 0;">{{item.title}}</p>
                    <div class="list_no_t">
                        <p class="list_no_t_p" style="font-size: 14px; color:red">{{item.starttime}} 开始</p>
                        <p class="list_no_t_p" style="font-size: 14px; color:red">{{item.endtime}} 结束</p>
                    </div>
                    <div class="list_no_c">
                        <van-row>
                            <van-col span="8" class="list_no_l">
                                <van-image
                                    width="100%"
                                    height="100%"
                                    :src="photo(item.photo)?photo(item.photo):img"
                                    />
                            </van-col>
                            <van-col span="15" offset="1">
                                <p class="list_no_r">{{item.describe == ""?'暂无介绍':item.describe}}</p>
                            </van-col>
                            <!-- <van-col span="4" offset="1" v-if="item.sign_up==1&&item.is_sign == ''">
                                <van-button @click.stop="register(item)" type="info">报名</van-button>
                            </van-col> -->
                        </van-row>
                       
                    </div>
                    <hr>
                </div>
            </van-list>
        </van-pull-refresh>

        <van-popup  closeable  v-model="show" class="popup" :close-on-click-overlay="false">
            <cashDeposit ref="posit" @application="application" />
        </van-popup>

    </div>
</template>

<script>
import { micro_post } from "../../../Http/api.js";
import cashDeposit from './cashDeposit.vue'
    export default {
        components: {
            cashDeposit,
        },
        data() {
            return {
                count: 1,
                isLoading: false,//下拉刷新
                loading: false, // 上拉加载
                finished: false, // 上拉加载完毕
                show:false,
                page:1,//页
                pageSize:10,//每页大小
                tableData:[],
                img:require("../../../assets/moren.jpg"),
            }
        },
        computed: {
            photo() {
                return (e)=>{
                    let arr =[]
                    if(e){
                        arr  = e.split(',')
                    }else{
                        return false
                    }
                    return arr[0]
                }
                
            }
        },
        methods: {

            lodData(pageSize,page){
                this.isLoading = true
                
                const data = {
                    url: "/index.php/BidOrder/bidList",
                    params: { shopId: this.$route.query.shopId,type:this.$route.query.type,pageSize:pageSize,page:page },
                    success: (res) => {
                        this.isLoading = false
                        if(res.data.data.length==0){
                            this.finished = true
                            this.tableData = res.data.data
                        }else{
                            this.finished = false
                            this.tableData = res.data.data
                        }
                    },
                    code_fun:{
                        1:(res)=>{
                             this.isLoading = false
                             this.finished = true
                        },
                    },

                };
                micro_post(data,this);
            },
            //报名弹窗
            register(val){
                if(val.is_money == 1) {
                    this.show = true
                    this.$nextTick(()=>{
                        this.$refs.posit.getInfo(val)
                    })
                }else{
                    const data = {
                        url: "index.php/BidManagement/bidSignOrder",
                        params: { shopId:this.$route.query.shopId,type:this.$route.query.type,act_id:val.id },
                        success: (res) => {
                            this.$toast.fail(res.msg)
                            this.lodData(10,1)
                        },
                       
                    };
                    micro_post(data,this);
                }
            },
            application(){
                this.show = false
                this.lodData(10,1)
            },
            //点击跳转
            information(val){
                 this.$router.push({path:'/particularsInfo',query:{id:val.id,shopId:this.$route.query.shopId,type:this.$route.query.type,mold:1}})
            },
            onRefresh() {
                this.finished = false
                this.lodData(10,1)
            },
            onLoad(){
                let this_ = this
                setTimeout(() => {
                    let page = this_.page + 1
                    this_.page = page
                    let pageSize = this_.pageSize
                    
                    this_.$http.post('/index.php/BidOrder/bidList',{pageSize,page,shopId: this.$route.query.shopId,type:this.$route.query.type,}).then(res=>{
                    
                        if(res.data.code == 0){
                            let arr = res.data.data.data
                            arr.forEach(element => {
                                this_.tableData.push(element)
                            });
                            if(this_.tableData.length >= res.data.data.total){
                                this.finished = true;
                            }else{
                                this.finished = false;
                            }
                            this.loading = false;

                        }
                    })
                   
                }, 1000);
            },
        },
    }
</script>

<style lang="scss" scoped>
.bid{
    height: 100%;
    .refresh{
        height: 100%;
        overflow: scroll;
        .list_no{
            width: calc(100% - 10px);
            padding-left: 10px;
            .list_no_t{
                display: flex;
                .list_no_t_p{
                    flex: 1;
                    text-align: center;
                }
            }
            .list_no_c{
                .list_no_l{
                   width: 100px;
                   height: 100px;
                }
                .list_no_r{
                  text-indent: 2em;
                  word-wrap: break-word;
                  line-height: 20px;
                }
            }
        }
    }
    
}
 .popup{
    width: 80%;
    padding: 20px;
}
</style>