<template>
    <div class="warper">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="(item, index) in images" :key="index">
                <van-image lazy-load :src="item" />
            </van-swipe-item>
        </van-swipe>
        <p class="title-p">
           {{title?title:'竞价类目名称'}}
        </p>
        <div class="warper-tab">
            <!-- <p @click="tabchange(0)" :class="[idx==0?'tab-p cur':'tab-p']">未开拍</p> -->
            <p @click="tabchange(1)" :class="[idx==1?'tab-p cur':'tab-p']">未出价</p>
       
            <p @click="tabchange(2)" :class="[idx==2?'tab-p cur':'tab-p']">
               <span class="tag">{{is_join}}</span>
                  已出价
            </p>
           
            <p @click="tabchange(3)" :class="[idx==3?'tab-p cur':'tab-p']" style="border-right: 0px">
                <span class="tag">{{is_succ}}</span>
                已中标
            </p>
        </div>
        <div class="listCon" v-if="idx==1">
            <NotBid ref="Bid" />
        </div>
        <div class="listCon" v-if="idx==2">
            <HaveOffered ref="Offered" />
        </div>
        <div class="listCon" v-if="idx==3">
            <HasWonTheBid ref="TheBid" />
        </div>
         <van-popup  closeable  v-model="show" class="popup" :close-on-click-overlay="false">
              <p style="text-align: center; ">温馨提示</p>
              <hr>
              <p style="text-indent: 2em;padding: 10px;"> 您当前没有{{title}}竞价资格，无法查看竞价详细信息。</p>
              <hr>
              <div class="btn-ok">
                  <!-- <van-button @click="show= false">取消</van-button> -->
                  <van-button type="info" @click="goApply">申请竞价资格</van-button>
              </div>
        </van-popup>
    </div>
</template>

<script>
import { isShouQuans, NO1ShouQuans } from '../../utils/impower.js'
import Vue from "vue";
import { Lazyload,Toast } from "vant";
Vue.use(Lazyload);
import { micro_post } from "../../Http/api.js";
import {deleteCookie} from '../../utils/env'
import NotBid from './components/NotBid.vue'
import HaveOffered from './components/HaveOffered.vue'
import HasWonTheBid from './components/HasWonTheBid.vue'

    export default {
        metaInfo(){
            return {
                 title: this.$route.query.shopId == '1078' ? '移动端拆船件' :    window.localStorage.getItem('getShopName')
            }
        },
        components: {
            NotBid,HaveOffered,HasWonTheBid,
        },
        data() {
            return {
                images: [
                    require("../../../public/images/banner_one.png"),
                    require("../../../public/images/banner_two.png"),
                ],
                count: 0,
                isLoading: false,
                DataList:[],
                loading: false,
                finished: false,
                show: false,
                username: '',
                phone: '',
                pic:"",
                ids:null,
                idx:1,
                title:'',
                is_join:0,
                is_succ:0,
                isShouQuan:'',
            }
        },
        async created () {
            this.ids = this.$route.query.type
            switch (this.ids) {
                case '1':
                    this.title = this.$route.query.shopId == '1078' ? "大宗竞价资格" : "大宗竞价资格";
                    break;
                case '2':
                    this.title = "大宗回用件竞价";
                    break;
                case '3':
                    this.title =  this.$route.query.shopId == '1078' ? "回用件竞价资格" :"回用件竞价资格";
                    break;
                default:
                    break;
            }
            
                 const toast = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });

                await NO1ShouQuans(this)
                toast.clear()//清除加载效果
               
                this.tabchange(1)
                this.getDaya()
                this.setImg()
            
            
            
        },
        mounted () {
            
        },
      
        methods: {
            tabchange(num){
                this.idx = num
                 this.getDaya()
                switch (num) {
                    case 1:
                        this.$nextTick(() => {
                            this.$refs.Bid.lodData(10,1)
                        });
                        break;
                    case 2:
                        this.$nextTick(() => {
                            this.$refs.Offered.lodData(10,1)
                        });
                        break;
                    case 3:
                        this.$nextTick(() => {
                            this.$refs.TheBid.lodData(10,1)
                        });
                        break;
                
                    default:
                        break;
                }
               
            },
            setImg(){
                const data = {
                    url: "/index.php/BidManagement/find",
                    params: { shopId: this.$route.query.shopId,type:0},
                    success: (res) => {
                        if(res.data.length!=0){
                            this.images = res.data
                        }else{
                            if(this.$route.query.shopId == 1078){
                                this.images = [
                                    require("../../../public/images/1078_1.jpg"),
                                    require("../../../public/images/1078_2.jpg"),
                                ]
                            }else{
                                 this.images = [
                                    require("../../../public/images/banner_one.png"),
                                    require("../../../public/images/banner_two.png"),
                                ]
                            }
                           
                        }
                        
                    },
                    code_fun:{
                        1:(res)=>{
                            this.show = true
                        },
                    },
                };
                micro_post(data,this);
            },
            goApply(){
                 this.$router.push({path:'/QualificationApplication',query:{shopId:this.$route.query.shopId,type:this.$route.query.type}})
            },
            getDaya(){
                 const data = {
                    url: "/index.php/BidOrder/bidNumDetail",
                    params: { shopId: this.$route.query.shopId,type:this.$route.query.type},
                    success: (res) => {
                       this.is_succ = res.data.is_succ
                       this.is_join = res.data.is_join
                    },
                    code_fun:{
                        1:(res)=>{
                            this.show = true
                        },
                    },
                };
                micro_post(data,this);
            },
             getQueryVariable(variable) {
                    var query = window.location.search.substring(1);
                    var vars = query.split("&");
                    for (var i=0;i<vars.length;i++) {
                            var pair = vars[i].split("=");
                            if(pair[0] == variable){return pair[1];}
                    }
                    return(false);
            },
             zhuan(url){
                let str = url
                let str1 = str.replace('https','')
                str1 = str1.replace('http','')
                let str2 = 'https'+ str1
                return str2
            },

            authorization(){
                 this.$http.post('/index.php/index/wechat.wechat/domain').then(res=>{
                        
                    if(res.data.code == 0){
                        let domain = this.zhuan(res.data.data)
                        let url = window.location.origin
                        let shopId = this.$route.query.shopId
                        let urls ="https://wechat.chexd.com/chaichejian/BiddingList.html?domain=" +domain + '&url=' +url + '&shopId=' + shopId
                   
                        this.$http.post('/index.php/index/wechat.bid_wechat/redirect_uri',{url:urls,shopId}).then(res=>{
                            if(res.data.code == 0) {

                            }else if(res.data.code == 2){
                                window.location.href = res.data.data
                            }else{
                                this.$toast.fail(res.data.msg)  
                            }
                        })
                    }
                })
                
            },
        },
    }
</script>

<style lang="scss" scoped>
.warper{
    font-size: 16px;
    .my-swipe {
        height: 4rem;
        .van-image {
            width: 100%;
            height: 100%;
        }
    }
    .title-p{
        text-align: center;
        font-size: 30px;
        height: 60px;
        line-height: 60px;
        
    }
    .warper-tab{
        width: 100%;
        height: 60px;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        display: flex;
        .tab-p{
            flex: 1;
            width: 100%;
            text-align: center;
            line-height: 60px;
            font-weight: bold;
            border-right: 1px solid #000;
            margin: 0;
            position: relative;
            &.cur{
                background-color:#F60 ;
                color: #fff;
            }
            .tag{
               
                font-size: 12px;
                position: absolute;
                top: 0;
                right: 0;
                padding: 3px;
                min-width: 30px;
                height: 20px;
                margin-top: -13px;
                background-color: red;
                color: #fff;
                line-height: 20px;
            }
        }
    }
    .listCon{
        height: calc(100vh - 60px - 60px - 4rem - 15px);
        margin-top: 15px;
    }
}
p{
    margin: 5px 0;
}

  .popup{
    width: 80%;
    
    padding: 20px;
   
}
.btn-ok{
    width: 100%;
    display: flex;
    .van-button{
        flex: 1;
    }
}

</style>